import AccountDefaults from '@configs/accountDefaults';
import AccountService from '@services/account.service';
import OrganizationService from '@services/organization.service';
import namespaceService from '@services/namespace.service';
import configurationService from '@services/config.service';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
const AccountContext = createContext(AccountDefaults);
export const useAccountContext = () => useContext(AccountContext);
const AccountProvider = ({ children }) => {
    const tahoeUrl = configurationService.TahoeUrl;
    const accountService = new AccountService(tahoeUrl);
    const organizationService = new OrganizationService(configurationService.OrganizationServiceBaseUrl);
    const namespace = namespaceService.getNamespace();
    const [account, setAccount] = useState(AccountDefaults);
    const [brand, setBrand] = useState('');
    const getAccount = async () => {
        const response = await accountService.getAccount();
        setAccount(response);
    };
    useEffect(() => {
        getAccount();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        const getBrand = async () => {
            const { brand } = await organizationService.GetBrandByNamespace(namespace);
            setBrand(brand);
        };
        if (namespace.length) {
            getBrand();
        }
    }, [account]);
    const values = useMemo(() => ({
        ...account,
        brand,
    }), [account, brand]);
    return (<AccountContext.Provider value={values}>
            <>{children}</>
        </AccountContext.Provider>);
};
export default AccountProvider;
