import {
    FBA_scenarioLibrary,
    createNotification,
    deleteNotification,
    editNotification,
    initiateNotification,
    useEmergency,
    useGeneral,
    viewNotification
} from 'common/constants/permissions';

import { checkResourceAccessByDivisions } from '../utils';

const canEditNotification = permissions => !!permissions[editNotification];
const canViewNotification = permissions => !!permissions[viewNotification];
const canCreateNotification = permissions => !!permissions[createNotification];
const canUseGeneral = permissions => !!permissions[useGeneral];
const canUseEmergency = permissions => !!permissions[useEmergency];
const canManageScenarioLibrary = permissions => !!permissions[FBA_scenarioLibrary];

const canDeleteNotification = (permissions, params) => {
    if (!params) {
        return !!permissions[deleteNotification];
    }

    return checkResourceAccessByDivisions(deleteNotification, params);
};

const canInitiateNotification = (permissions, params) => {
    if (!params) {
        return !!permissions[initiateNotification];
    }

    return checkResourceAccessByDivisions(initiateNotification, params);
};

export default {
    canEditNotification,
    canViewNotification,
    canCreateNotification,
    canDeleteNotification,
    canInitiateNotification,
    canManageScenarioLibrary,
    canUseGeneral,
    canUseEmergency
};
