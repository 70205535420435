import jwtService from './jwt.service';
import configurationService from './config.service';
import ApiService from './api.service';
import oidcManagerService from './oidc.manager.service';
import errorHandlingService from './error.handling.service';
import CookieService from './cookie.service';
import cookieKeys from '../common/constants/cookieKeys';
import namespaceService from './namespace.service';
import identityService from './identity.service';
const AuthService = {
    async signIn(clientErrorCode) {
        const manager = oidcManagerService.GetManager();
        const user = await manager.getUser();
        if (user && user.expired) {
            await this.signOut();
            return;
        }
        const extraParams = {};
        if (clientErrorCode) {
            extraParams.client_error = clientErrorCode;
        }
        await manager.clearStaleState();
        await manager.signinRedirect({
            extraQueryParams: {
                ...extraParams,
                ...manager.settings.extraQueryParams,
            },
        });
    },
    async signOut(mir3Logout = true, clientErrorCode = undefined) {
        if (mir3Logout) {
            const apiService = new ApiService(configurationService.MIR3ApiBaseUrl);
            const cookieService = new CookieService();
            const domain = namespaceService.getDomainWithoutNamespace();
            // the following cookies are set to a domain without a namespace prefix,
            // that's why the same domain should be used for deletion
            cookieService.deleteCookie(cookieKeys.loggedInNamespace, domain);
            cookieService.deleteCookie(cookieKeys.originURL, domain);
            //Invalidate all open sessions in mir3 based on user authorization token
            apiService.post('/actions/session/invalidate').catch((error) => {
                errorHandlingService.logError(error);
            });
        }
        const manager = oidcManagerService.GetManager(true, clientErrorCode);
        await manager.signoutRedirect();
    },
    async updateState() {
        const manager = oidcManagerService.GetManager();
        await manager.signinRedirectCallback();
        jwtService.setClaims();
    },
    async updateStateWithRedictParameter(locationString) {
        await this.updateState();
        location = locationString ? locationString : '';
    },
    get isAuthenticated() {
        return !jwtService.isTokenExpired();
    },
    removeAuthData() {
        jwtService.removeClaims();
        jwtService.removeToken();
    },
    async toggleImpersonation(subjectId) {
        try {
            const { IdentityBaseClientId } = configurationService;
            const data = new FormData();
            data.append('client_id', IdentityBaseClientId);
            data.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
            data.append('actor_token_type', 'urn:ietf:params:oauth:token-type:access_token');
            data.append('actor_token', jwtService.getToken()?.replace('Bearer ', ''));
            //if you don't have a subject Id, you are exiting impersonator flow
            if (subjectId) {
                data.append('subject_sub', subjectId);
            }
            const oidcUserData = await identityService.impersonateUser(data);
            jwtService.toggleImpersonation(oidcUserData);
            return oidcUserData?.access_token || '';
        }
        catch (error) {
            console.error(error);
        }
    },
};
export default AuthService;
