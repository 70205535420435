import { default as lookOut } from './lookOut';
import {
    canCreateSharedLockBoxFile,
    canDeleteSharedLockBoxFile,
    canEditSharedLockBoxFile,
    canViewLockBox
} from './lockBox';
import { FBA_OPIncidentManagement } from 'common/constants/permissions';

export const canViewIncidentManagement = permissions => !!permissions[FBA_OPIncidentManagement];

export default {
    ...lookOut,
    canViewLockBox,
    canCreateSharedLockBoxFile,
    canDeleteSharedLockBoxFile,
    canEditSharedLockBoxFile,
    canViewIncidentManagement
};
