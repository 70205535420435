/* eslint-disable no-unused-vars */
import axios from 'axios';
import { stringify } from 'qs';
import { fp as _ } from '@onsolve/onsolve-ui-components';

import Utility from '../common/utility/utility';

import historyService from './history.service';
import jwtService from './jwt.service';

const apiWhiteList = ['/api/signin', '/res/locales[.]json', '/actions/verbiageLookup/.*'];
const regexWhiteList = new RegExp(`^(${apiWhiteList.join('|')})$`);
const responseInterceptors = [handleResponseInterception, handleResponseErrorInterception];

axios.defaults.headers.common = {
    'Content-Type': 'application/json'
};

const getResponseInterceptors = params => params.responseInterceptors || responseInterceptors;

const responseBodyWithHeaders = (headersToInclude, responseSelector) => {
    return res => {
        return {
            data: responseSelector(res),
            headers: _.pick(res.headers, headersToInclude)
        };
    };
};

const createHttpClient = ({
    baseURL = Utility.url,
    headers = {},
    paramsSerializerConfig = { arrayFormat: 'repeat' },
    params = {}
} = {}) => {
    const client = axios.create({ baseURL, headers });

    client.interceptors.request.use(handleRequestInterception);
    client.interceptors.response.use(...getResponseInterceptors(params));
    client.defaults.paramsSerializer = params => stringify(params, { ...paramsSerializerConfig, encode: false });
    return client;
};

class ApiService {
    constructor(baseURL, options = {}) {
        const { paramsSerializerConfig, headers, params } = options;

        this.baseURL = baseURL;
        this.client = createHttpClient({ baseURL, paramsSerializerConfig, headers, params });
    }

    all(...requestsArray) {
        return axios.all(requestsArray);
    }

    get(resource, params = {}) {
        return this.client.get(resource, { params });
    }

    getWithHeaders(
        resource,
        params = {},
        requestHeaders = {},
        responseHeaders = [],
        responseSelector = res => res.data.data,
        responseType = ''
    ) {
        const instance = axios.create({ headers: _.assign({}, requestHeaders), responseType: responseType });

        instance.interceptors.request.use(handleRequestInterception);
        instance.interceptors.response.use(
            responseBodyWithHeaders(responseHeaders, responseSelector),
            handleResponseErrorInterception
        );
        return instance.get(resource, { params: params, baseURL: this.baseURL });
    }

    post(resource, body = {}, config = {}) {
        return this.client.post(resource, body, config);
    }

    put(resource, body = {}) {
        return this.client.put(resource, body);
    }

    patch(resource, body = {}) {
        return this.client.patch(resource, body);
    }

    del(resource, params = {}, data = {}) {
        return this.client.delete(resource, { params, data });
    }

    request(config) {
        return this.client.request(config);
    }

    //TODO: Deprecated. You can just as easily use the constructor and it causes issues in testing.
    static create({ baseURL, paramsSerializerConfig, headers, params }) {
        return new this(baseURL, { paramsSerializerConfig, headers, params });
    }
}

function handleRequestInterception(config) {
    const token = jwtService.getToken();
    let {
        headers: { common }
    } = config;

    // TODO: handle refresh token before sending real request, make a queue for all pending requests until refresh token done
    if (!regexWhiteList.test(config.url) && token) {
        common = {
            ...common,
            Authorization: `Bearer ${token}`
        };
        config.headers.common = common;
    }
    
    if (config.url.includes('/beacon/1/')) {
        const { token } = JSON.parse(localStorage.getItem('riAccountInfo')) || {};

        common = {
            ...common,
            Authorization: `SVTok1 ${token}`
        };
        config.headers.common = common;
    }

    return config;
}

function handleResponseInterception(response) {
    return response && response.data;
}

function handleResponseErrorInterception(error) {
    if (error.response) {
        const { status } = error.response;

        if (status === 500) {
            historyService.forwardTo('/error/500');
        }
    }

    return Promise.reject(error);
}

export { createHttpClient, handleResponseErrorInterception };
export default ApiService;
