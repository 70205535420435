import cookieKeys from 'common/constants/cookieKeys';
import configurationService from './config.service';
import CookieService from './cookie.service';
const cookieService = new CookieService();
const namespaceService = {
    getNamespace: () => {
        let urlPattern;
        const { UrlPattern: configUrlPattern } = configurationService;
        if (!configUrlPattern) {
            return;
        }
        const host = window.location.hostname.toLowerCase();
        const customerPlaceholder = '{customer}';
        const wwwPrefix = 'www.';
        if (!configUrlPattern.startsWith(wwwPrefix)) {
            urlPattern = `${wwwPrefix}${configUrlPattern}`;
        }
        const urlPatternPieces = new Set(urlPattern.split('.').filter((x) => x !== customerPlaceholder));
        const actualUrlPieces = host.split('.');
        const customerNamespace = actualUrlPieces.find((x) => !urlPatternPieces.has(x));
        return customerNamespace;
    },
    //! ON-30299: In order to support customers like WTP who want their own CNAME record to work with MFE and Account
    //! Portal we have to update the way that we handle identity server redirects. To that end, we will store the user's
    //! origin in a cookie and use that to redirect the user once we exchange the identity access code for a JWT.
    setOriginCookie: () => {
        const domain = namespaceService.getDomainWithoutNamespace();
        const existingCookie = cookieService.getCookieValue(cookieKeys.originURL);
        if (!existingCookie) {
            const expireTime = new Date();
            expireTime.setHours(expireTime.getHours() + 1);
            cookieService.setCookie(cookieKeys.originURL, window.location.href, {
                domain,
                expires: expireTime,
                ...(window.location.protocol === 'https:' && { secure: 'secure' }),
            });
        }
    },
    getProtocol: () => {
        return window.location.protocol;
    },
    getOriginWithNamespace: () => {
        return window.location.origin.toLowerCase();
    },
    getOriginWithoutNamespace: () => {
        const origin = window.location.origin.toLowerCase();
        const namespace = namespaceService.getNamespace();
        return namespace ? origin.replace(`${namespace}.`, '') : origin;
    },
    getClientOrigin: (href) => {
        if (href) {
            return new URL(href).origin + '/';
        }
    },
    getDomainWithoutNamespace: () => {
        const hostname = window.location.hostname.toLowerCase();
        const namespace = namespaceService.getNamespace();
        return namespace ? hostname.replace(`${namespace}.`, '') : hostname;
    },
    getExternalDomain: () => {
        const { ExternalDomainName } = configurationService;
        return ExternalDomainName || namespaceService.getDomainWithoutNamespace();
    },
    getCombinedNamespaceURL: (namespace) => {
        const protocol = `${window.location.protocol}//`;
        return `${protocol}${namespace}.${window.location.href.slice(protocol.length)}`;
    },
};
export default namespaceService;
