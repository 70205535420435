import React from 'react';
const NavigationIcon = () => {
    return (<svg width="60" height="48" viewBox="0 0 60 48" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.4808 18.3176L32.6397 15.8526C33.423 15.5829 34.2767 15.9992 34.5464 16.7825L38.3964 27.9637C38.6661 28.747 38.2497 29.6006 37.4664 29.8703L30.3075 32.3354C29.5242 32.6051 28.6706 32.1887 28.4008 31.4054L24.5509 20.2243C24.2811 19.441 24.6975 18.5873 25.4808 18.3176ZM33.6008 17.1081C33.5109 16.847 33.2264 16.7082 32.9653 16.7981L25.8063 19.2632C25.5452 19.3531 25.4065 19.6376 25.4964 19.8987L29.3464 31.0799C29.4363 31.341 29.7208 31.4797 29.9819 31.3898L37.1409 28.9248C37.402 28.8349 37.5407 28.5504 37.4508 28.2893L33.6008 17.1081Z" fill="white"/>
            <g filter="url(#filter0_d_177_1450)">
                <rect x="21.5" y="19.0196" width="11.1667" height="13.8254" rx="1" fill="#213955"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M31.6667 18.5196H22.5C21.6716 18.5196 21 19.1912 21 20.0196V31.8451C21 32.6735 21.6716 33.3451 22.5 33.3451H31.6667C32.4951 33.3451 33.1667 32.6735 33.1667 31.8451V20.0196C33.1667 19.1912 32.4951 18.5196 31.6667 18.5196ZM31.6667 19.5196C31.9428 19.5196 32.1667 19.7435 32.1667 20.0196V31.8451C32.1667 32.1212 31.9428 32.3451 31.6667 32.3451H22.5C22.2239 32.3451 22 32.1212 22 31.8451V20.0196C22 19.7435 22.2239 19.5196 22.5 19.5196H31.6667ZM31.0397 21.6783C31.0397 21.4022 30.8159 21.1783 30.5397 21.1783H24.1587C23.8826 21.1783 23.6587 21.4022 23.6587 21.6783C23.6587 21.9545 23.8826 22.1783 24.1587 22.1783H30.5397C30.8159 22.1783 31.0397 21.9545 31.0397 21.6783ZM29.4762 23.3053C29.7524 23.3053 29.9762 23.5292 29.9762 23.8053C29.9762 24.0815 29.7524 24.3053 29.4762 24.3053H24.1587C23.8826 24.3053 23.6587 24.0815 23.6587 23.8053C23.6587 23.5292 23.8826 23.3053 24.1587 23.3053H29.4762ZM27.8492 25.9323C27.8492 25.6562 27.6254 25.4323 27.3492 25.4323H24.1587C23.8826 25.4323 23.6587 25.6562 23.6587 25.9323C23.6587 26.2085 23.8826 26.4323 24.1587 26.4323H27.3492C27.6254 26.4323 27.8492 26.2085 27.8492 25.9323Z" fill="white"/>
            <defs>
                <filter id="filter0_d_177_1450" x="21.5" y="18.0196" width="12.1667" height="14.8254" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="1" dy="-1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0 0.333333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_177_1450"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_177_1450" result="shape"/>
                </filter>
            </defs>
        </svg>);
};
export default NavigationIcon;
