import jwt from 'jwt-decode';
import LocalStorageUtility from '../common/utility/localStorageUtility';
let decodedPayload = null;
class JwtService {
    getOrganizationId() {
        return this.getPayload()?.org;
    }
    getOrganizationUuid() {
        return this.getPayload()?.org_uuid;
    }
    getUserUuid() {
        return this.getPayload()?.user_uuid;
    }
    getUserAssetId() {
        return this.getPayload()?.userassetid;
    }
    getPermissionsClaims() {
        // eslint-disable-next-line no-unused-vars
        const { sub, org, exp, ...permissions } = this.getPayload() || {};
        // permissions.FBA_selfRegPortals = 1;
        return permissions;
    }
    getPayload() {
        if (!decodedPayload) {
            decodedPayload = LocalStorageUtility.getData('claims');
        }
        return { ...decodedPayload };
    }
    isTokenExpired() {
        const data = LocalStorageUtility.selectContaining('oidc-user.');
        const exp = data?.expires_at;
        if (exp) {
            const date = new Date(0);
            date.setUTCSeconds(exp);
            return date.valueOf() <= new Date().valueOf();
        }
        return true;
    }
    getToken() {
        const data = LocalStorageUtility.selectContaining('oidc-user.');
        if (data?.access_token) {
            return data.access_token;
        }
        return null;
    }
    setClaims() {
        const data = LocalStorageUtility.selectContaining('oidc-user.');
        if (data?.access_token) {
            const claims = jwt(data.access_token);
            LocalStorageUtility.setData('claims', claims);
        }
    }
    removeToken() {
        LocalStorageUtility.clearKeysContaining('oidc-user.');
    }
    removeClaims() {
        LocalStorageUtility.clearKeys(['claims']);
    }
    toggleImpersonation(oidcUserData) {
        if (oidcUserData) {
            const { access_token, refresh_token } = oidcUserData;
            const key = LocalStorageUtility.getKeyContaining('oidc-user.');
            const data = LocalStorageUtility.getData(key);
            data.access_token = access_token;
            data.refresh_token = refresh_token;
            LocalStorageUtility.setData(key, data);
            LocalStorageUtility.setData('claims', jwt(access_token));
        }
    }
    isImpersonatingUser() {
        const claims = this.getPermissionsClaims();
        return claims.act?.sub !== undefined;
    }
}
export default new JwtService();
