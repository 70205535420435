import { checkIn, editMyProfile, viewMyProfile } from 'common/constants/permissions';

const canViewMyProfile = permissions => !!permissions[viewMyProfile];
const canViewCheckIn = permissions => !!permissions[checkIn];
const canEditMyProfile = permissions => !!permissions[editMyProfile];

export default {
    canViewMyProfile,
    canViewCheckIn,
    canEditMyProfile
};
