const AccountDefaults = {
    accountId: -1,
    organizationId: -1,
    globalPermissions: [],
    features: [],
    addresses: [],
    csrfToken: '',
    organizationName: '',
    loggedInUser: {
        firstName: '',
        lastName: '',
        username: '',
        locale: '',
        userUuid: undefined,
        jobTitle: '',
    },
    division: undefined,
    handleSignOut: () => { },
};
export default AccountDefaults;
