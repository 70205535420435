import * as permissionNames from 'common/constants/permissions';
import { getFeature } from 'services/feature.service';

const canManageDoNotContact = permissions => {
    return (
        !!permissions[permissionNames.FBA_doNotContact] && !!permissions.viewDoNotContact && getFeature('doNotContact')
    );
};

export default {
    canManageDoNotContact
};
