import { notificationGroups, notificationInbox, FBA_linkedAlerts } from 'common/constants/permissions';

import notifications from './notifications';

const canViewAlertInbox = permissions => permissions[notificationInbox];

const canViewAlerts = permissions =>
    notifications.canInitiateNotification(permissions) || notifications.canViewNotification(permissions);

const canViewScheduledAlerts = permissions =>
    notifications.canInitiateNotification(permissions) || notifications.canViewNotification(permissions);

const canViewBulkAlerts = permissions =>
    !!permissions[FBA_linkedAlerts] && 
    (notifications.canInitiateNotification(permissions) || notifications.canViewNotification(permissions)) &&
    permissions[notificationGroups];

const canViewScenarioLibrary = permissions => {
    return notifications.canCreateNotification(permissions) && notifications.canManageScenarioLibrary(permissions);
};

export default {
    canViewAlertInbox,
    canViewAlerts,
    canViewScheduledAlerts,
    canViewBulkAlerts,
    canViewScenarioLibrary
};
