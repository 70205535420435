const defaultLanguage = {
    lcId: 'en_US',
    direction: 'LTR',
    name: 'English',
    translatedName: 'English',
    isoCode: 'en-US',
    cultureRegionCode: 'US',
    cultureDisplayName: 'English (United States)',
    cultureLanguageCode: 'en'
};

export default defaultLanguage;
