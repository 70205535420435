import { LoadingOverlay } from '@onsolve/onsolve-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import LocalStorageUtility from '../common/utility/localStorageUtility';
import AssetUISourceFiles from '../ExternalApps/AssetUI/asset-manifest.json';
import HistoricalReportingSourceFiles from '../ExternalApps/HistoricalReporting/asset-manifest.json';
import ControlCenterSourceFiles from '../ExternalApps/ControlCenter/asset-manifest.json';
import CriticalCommsSourceFiles from '../ExternalApps/CriticalComms/asset-manifest.json';
import RISourceFiles from '../ExternalApps/RiskIntelligence/asset-manifest.json';
import authService from '../services/auth.service';
import './Microfrontend.scss';
import { first } from 'lodash';
import configurationService from '@services/config.service';

const getJSFiles = (host, manifestValues, mainValue, scriptId, name) => {
    return manifestValues
        .filter((value) => value.endsWith('js'))
        .reduce((sum, value) => {
            sum.push(
                // If the script already exists, don't add it again
                !document.getElementById(value) || !document.getElementById(scriptId)
                    ? new Promise((resolve) => {
                          const path = `${host}${value}`;
                          const script = document.createElement('script');

                          script.id = value === mainValue ? scriptId : value;
                          script.ariaLabel = name;

                          script.onload = () => resolve();
                          script.src = path;
                          document.body.after(script);
                      })
                    : undefined
            );
            return sum;
        }, []);
};

const getCSSFiles = (host, manifestValues, linkId, name) => {
    return manifestValues
        .filter((value) => value.endsWith('css'))
        .reduce((sum, value) => {
            sum.push(
                new Promise((resolve) => {
                    const path = `${host}${value}`;
                    const link = document.createElement('link');

                    link.className = linkId;
                    link.onload = () => resolve();
                    link.rel = 'stylesheet';
                    link.type = 'text/css';
                    link.href = path;
                    link.ariaLabel = name;
                    document.head.appendChild(link);
                })
            );
            return sum;
        }, []);
};

const getManifestFile = (name) =>
    ({
        RiskIntelligence: RISourceFiles,
        CriticalComms: CriticalCommsSourceFiles,
        ControlCenter: ControlCenterSourceFiles,
        AssetUI: AssetUISourceFiles,
        HistoricalReporting: HistoricalReportingSourceFiles,
    }[name]);

class MicroFrontend extends React.Component {
    constructor(props) {
        super(props);
    }

    // Link to more info about async componentDidMount: https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good
    async componentDidMount() {
        // Still don't understand what is happening here, but the webpack chunking seems to break
        // when switching apps. Unsetting the chunk app array will ensure we refetch the references to
        // the new app being loaded in the runtime bundle
        window.webpackChunkapp = undefined;
        const { name, host, history, mapPreferences, setMFEi18n, locale, handleImpersonatorLogIn } = this.props;
        const environment = LocalStorageUtility.getValue('environment');

        if (!name) {
            return null;
        }

        const scriptId = `micro-frontend-script-${name}`;
        const linkId = `micro-frontend-link-${name}`;

        const formatFileURL = url => {
            let fileurl = url;
            if (fileurl.includes('/api/file/content-image/')) {
                if (!fileurl.includes('https://filestorageservice')) {
                    const baseURL = configurationService.FileStorageServiceApiBaseUrl;

                    const fileId =fileurl.match(/\/api\/file\/content-image\/([\w-]+)$/);
                    fileurl = fileId ? baseURL + first(fileId) : fileurl;
                    fileurl = fileurl.replace('//api/file/content-image/', '/api/file/content-image/');
                }
            }
            return fileurl;
        }

        const renderMicroFrontend = () => {
            window[`render${name}`] &&
                window[`render${name}`]({
                    containerId: `${name}-container`,
                    history: history,
                    mapPreferences: mapPreferences,
                    translationCallback: setMFEi18n,
                    signOutCallback: authService.signOut,
                    locale: locale,
                    impersonatorCallback: handleImpersonatorLogIn,
                });

            // update all image sources to show apps assets
            const observer = new MutationObserver(() => {
                const images = document.getElementById(`${name}-container`)?.getElementsByTagName('img');

                if (images?.length) {
                    Array.from(images).forEach((image) => {
                        // Checking if the image source is mostly the same as the host, to not change external image sources
                        if (
                            !image.src.startsWith(host) &&
                            image.src.includes(new URL(host).host) &&
                            !image.src.startsWith('data') &&
                            !image.src.startsWith('file')
                        ) {
                            const path = image.src.startsWith('http') ? new URL(image.src).pathname : image.src;

                            image.src = formatFileURL(`${host}/${path}`);
                        }
                    });
                }
            });

            observer.observe(document.getElementById(`${name}-container`) || document.body, {
                childList: true,
                subtree: true,
            });
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return;
        }

        const noCacheHeaders = new Headers();
        noCacheHeaders.append('pragma', 'no-cache');
        noCacheHeaders.append('cache-control', 'no-cache');

        const manifest =
            environment !== 'Development'
                ? await fetch(`/${name}/asset-manifest.json`, { cache: 'no-store', headers: noCacheHeaders }).then(
                      (response) => response.json()
                  )
                : getManifestFile(name);

        const manifestKeys = Object.keys(manifest['files'] || {}).length
            ? Object.keys(manifest['files'])
            : Object.keys(manifest);

        const originalManifestValues = Object.values(manifest['files'] || {}).length
            ? Object.values(manifest['files'])
            : Object.values(manifest);

        //* map /dist to the beginning of the paths for development mode
        const manifestValues = originalManifestValues.map((value) =>
            environment.toLowerCase() === 'development' ? `/dist${value}` : value
        );

        const [mainKey] = manifestKeys.filter((key) => key === 'main' || key === 'main.js');

        const mainValue = manifest[mainKey] || manifest['files'][mainKey];

        const promises = [
            ...getJSFiles(host, manifestValues, mainValue, scriptId, name),
            ...getCSSFiles(host, manifestValues, linkId, name),
        ];

        Promise.allSettled(promises).then(() => {
            renderMicroFrontend();
        });
    }

    componentWillUnmount() {
        const { name } = this.props;

        if (!name) {
            return;
        }

        // Remove all app style links that were added
        document.querySelectorAll(`.micro-frontend-link-${name}`).forEach((element) => element.remove());

        // Remove main app script that was added
        document.querySelectorAll(`#micro-frontend-script-${name}`).forEach((element) => element.remove());

        window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    }

    render() {
        const { name } = this.props;

        return (
            <main id={`${name}-container`}>
                <LoadingOverlay className={'mfe-app-loading-overlay'} description="Please stand by." overlay />
            </main>
        );
    }
}

MicroFrontend.propTypes = {
    history: PropTypes.object,
    host: PropTypes.string,
    loadCounter: PropTypes.number,
    name: PropTypes.string,
    setMFEi18n: PropTypes.func,
    handleImpersonatorLogIn: PropTypes.func,
    mapPreferences: PropTypes.object
};

export default MicroFrontend;
