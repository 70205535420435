const routeCheck = (url = '', appName) => {
    let route = url;

    // If you have a match.url, it's entirely possible that the url already contains /appName here...
    const appURL = url.toLowerCase().includes(appName.toLowerCase());
    const relativePath = url.includes('./');

    if (!appURL && !relativePath) {
        route = url[0] == '/' ? `/${appName}${url}` : `/${appName}/${url}`;
    }

    return route;
};

export const updatedPush = (push, appName) => (url, { ...optionalProps }) => {
    return Object.keys(optionalProps).length
        ? push(routeCheck(url, appName), optionalProps)
        : push(routeCheck(url, appName));
};


export const updateHistoryWithFilterId = (history) => {
    const originalPush = history.push;

    history.push = (path, state) => {
        const currentSearchParams = new URLSearchParams(window.location.search);
        const currentFilterId = currentSearchParams.get('filterId');

        const newURLObject = new URL(path || window.location.pathname, window.location.href);
        const newSearchParams = newURLObject.searchParams;
        const newFilterId = newSearchParams.get('filterId');

        const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));

          if (selectedFilter?.id) {
              newSearchParams.set('filterId', currentFilterId || newFilterId || selectedFilter.id);
          }


        newURLObject.search = newSearchParams.toString();
        path = newURLObject.pathname + newURLObject.search + newURLObject.hash;

        originalPush.call(history, path, state);
    };

    return history;
};
