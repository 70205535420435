export const updateDocumentMetadata = (title, faviconPath) => {
    document.title = title;
    // The "NOSONAR" comment is used to ignore the SonarQube warning:
    // "This assertion is unnecessary since it does not change the type of the expression."
    let link = document.querySelector("link[rel*='icon']"); //NOSONAR
    if (!link) {
        link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = faviconPath;
};
