import { createIntl, createIntlCache } from 'react-intl';

const defaultConfig = {
    locale: 'en'
};
let intl = null;

const I18nService = {
    get intl() {
        if (!intl) {
            intl = createIntl(defaultConfig, this.cache);
        }
        return intl;
    },
    cache: createIntlCache(),
    create(config = defaultConfig) {
        intl = createIntl(config, this.cache);
        return intl;
    }
};

export default I18nService;
