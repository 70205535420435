import { FBA_recipientSchedule, createGroup, editUser, viewGroup, viewUser } from 'common/constants/permissions';

import { checkResourceAccessByDivisions } from '../utils';

const canViewGroups = permissions => !!permissions[viewGroup];

const canViewContacts = (permissions, params) => {
    if (!params) {
        return !!permissions[viewUser];
    }
    return checkResourceAccessByDivisions(viewUser, params);
};

const canViewSchedules = permissions => !!permissions[FBA_recipientSchedule];

const canCreateGroups = permissions => !!permissions[createGroup];

const canEditContacts = (permissions, params) => {
    if (!params) {
        return !!permissions[editUser];
    }

    return checkResourceAccessByDivisions(editUser, params);
};

export default {
    canViewGroups,
    canViewContacts,
    canViewSchedules,
    canCreateGroups,
    canEditContacts
};
