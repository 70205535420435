import CookieService from 'services/cookie.service';
import namespaceService from 'services/namespace.service';
export const i18nSetCookie = (ui_lang, name = 'lang') => {
    const cookieService = new CookieService();
    if (ui_lang) {
        const cookieOptions = { 'max-age': 120 };
        if (!location.hostname.endsWith('localhost')) {
            //Do not set a cookie "domain" if running on localhost
            cookieOptions.domain = '.' + namespaceService.getDomainWithoutNamespace();
        }
        cookieService.setCookie(name, ui_lang, cookieOptions);
    }
};
