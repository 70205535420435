import React from 'react';
import styles from './BetaToastr.module.scss';
import { t } from '@lingui/macro';
const BetaToastrContent = ({ onClose }) => (<div className={styles.betaToastrContent}>
        <div className={styles.betaToastrHeader}>
            <span className={styles.betaToastrHeaderText}>{t `OnSolve has made updates!`}</span>
            <a onClick={onClose} className={styles.betaToastrWhatsNew} target="_blank" rel="noopener noreferrer" href="https://userdocuments.onsolve.net/WhatIsNewInBeta.pdf">
                {t `see what's new`}
            </a>
        </div>
        <span className={styles.betaToastrNote}>{t `Note:`}</span>
        &nbsp;
        {t `Your experience includes ALL features OnSolve has to offer, which may differ from your contracted subscription to the service.`}
    </div>);
export default BetaToastrContent;
