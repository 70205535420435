import configurationService from './config.service';
export const getFeature = (name) => {
    if (!name) {
        return true;
    }
    const { FeatureFlags } = configurationService;
    return typeof FeatureFlags[name] === 'undefined' || FeatureFlags[name];
};
export default {
    getFeature,
};
