import {
    useTopic,
    viewWeatherAndEvents,
    weatherAndEventsAustralia,
    weatherAndEventsCanada,
    weatherAndEventsEurope,
    weatherAndEventsGlobal,
    weatherAndEventsJapan,
    weatherAndEventsUSA,
    FBA_ttKeyword,
} from 'common/constants/permissions';

const canViewTopics = (permissions) => !!permissions[useTopic];

const canViewWeatherAndEvents = (permissions) =>
    [
        weatherAndEventsAustralia,
        weatherAndEventsCanada,
        weatherAndEventsEurope,
        weatherAndEventsJapan,
        weatherAndEventsUSA,
        weatherAndEventsGlobal,
    ].some((x) => permissions.hasOwnProperty(x)) && permissions.hasOwnProperty(viewWeatherAndEvents);

const canManageTtKeyword = (permissions) => !!permissions[FBA_ttKeyword];

export default {
    canViewTopics,
    canViewWeatherAndEvents,
    canManageTtKeyword,
};
