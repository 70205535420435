export default class CookieService {
    getCookie = (name) => {
        return document?.cookie?.match(new RegExp('(^| )' + name + '=([^;]+)'));
    };
    getCookieValue = (name) => {
        const cookie = this.getCookie(name);
        return cookie ? decodeURIComponent(cookie[2]) : '';
    };
    setCookie = (name, value, options = {}) => {
        options = {
            ...options,
            path: options.path || '/',
        };
        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
        let optionKey;
        for (optionKey in options) {
            if (!!options[optionKey] !== true) {
                cookie += `; ${optionKey}=${options[optionKey]}`;
            }
        }
        document.cookie = cookie;
    };
    deleteCookie = (name, domain) => {
        this.setCookie(name, '', { 'max-age': -1, ...(domain && { domain }) });
    };
}
