import React from 'react';
import { SvgIcon } from '@onsolve/onsolve-ui-components';
import styles from './TravelIcon.module.scss';
const TravelIcon = () => (<SvgIcon className={styles.travelIcon}>
        <g transform="translate(0 .718)" fill="currentColor'" fillRule="evenodd">
            <circle fill="none" fillRule="nonzero" cx={10.558} cy={13.758} r={1.28}/>
            <path d="M3.52 12.478a1.28 1.28 0 100 2.56 1.28 1.28 0 000-2.56zM15.357 0h-.39a1.28 1.28 0 00-1.272 1.134l-1.078 9.156a.667.667 0 01-.661.588H.64a.64.64 0 000 1.28h11.908a1.28 1.28 0 001.271-1.133l1.133-9.598a.167.167 0 01.166-.147h.242a.64.64 0 000-1.28h-.003z" fill="none" fillRule="nonzero"/>
            <path d="M2.56 9.919h7.678a1.6 1.6 0 001.6-1.6v-3.84a1.6 1.6 0 00-1.6-1.599H8.805a.167.167 0 01-.167-.167V2.24a1.6 1.6 0 00-1.6-1.6h-1.28a1.6 1.6 0 00-1.599 1.6v.473a.167.167 0 01-.166.167H2.559a1.6 1.6 0 00-1.6 1.6v3.839a1.6 1.6 0 001.6 1.6zm7.038-5.12a.32.32 0 110 .64H3.199a.32.32 0 010-.64h6.399zM3.199 7.36h6.399a.32.32 0 010 .64H3.199a.32.32 0 010-.64zM5.44 2.24a.324.324 0 01.32-.32h1.28a.324.324 0 01.32.32v.473a.167.167 0 01-.167.167H5.605a.167.167 0 01-.166-.167V2.24z" fill="none" fillRule="nonzero"/>
        </g>
    </SvgIcon>);
export default TravelIcon;
