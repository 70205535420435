import ApiService from './api.service';

const errorHandling = {
    logError
};

async function logError(error, info) {
    // eslint-disable-next-line no-console
    console.error('An error has occurred:', error);
    try {
        let apiService = new ApiService();

        let Message = (error && error.toString()) + ' ' + (info && info.componentStack);

        await apiService.post('ClientError/SaveException', { Message }, false);
    } catch (loggerError) {
        // eslint-disable-next-line no-console
        console.log(' failed to log error', loggerError);
    }
}

export default errorHandling;
