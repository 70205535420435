import { orgAdmin } from 'common/constants/permissions';

const canOrganizationAdmin = permissions => !!permissions[orgAdmin];

/*

const canNotificationDashboard = permissions => false;
const canEditGisConfiguration = permissions => false;

*/
export default {
    canOrganizationAdmin
};
