import ApiService from './api.service';
export default class AccountService extends ApiService {
    constructor(tahoeUrl) {
        super(tahoeUrl);
    }
    getAccount = async () => {
        try {
            const response = await this.get('/actions/session');
            return response;
        }
        catch (error) {
            console.log(error);
        }
    };
}
