import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { AlertModal, Button } from '@onsolve/onsolve-ui-components';
import { t, Trans } from '@lingui/macro';
import { useSessionContext } from '@context/sessionContext';
import styles from './SessionTimeoutModal.module.scss';
import AuthService from '@services/auth.service';
import JwtService from '@services/jwt.service';
import LocalStorageUtility from '../../common/utility/localStorageUtility';
// Events that will cause the inactivity timer to restart
const activityEvents = ['change', 'click', 'keydown', 'input', 'load', 'scroll', 'touch'];
const defaultLogoutTimeout = 3600000; // 60 minutes
const defaultLogoutOffset = 60000; // 1 minute
/*
 * Actual timer function that will sign the user out after {logoutTimeout} time has elapsed when modal is shown
 * This timer will be reset any time the user either signs out or chooses to stay logged in
 */
let automaticLogoutTimer;
/*
 * Actual timer function that will show the inactivity modal after {popupTimeout} time has elapsed when modal is not shown
 * This timer will be reset any time a user performs one of the events listed in {activityEvents}, or if a user signs out or remains logged in
 */
let sessionPopupTimer;
const localStorageKey = `SESSION_TIMEOUT_${JwtService.getOrganizationId()}`;
const SessionTimeoutModal = () => {
    const { identityContext: { attributes: { 'security:uiSessionTimeoutInSeconds': sessionTimeout } = {} } = {} } = useSessionContext();
    const [storedTimeout, setStoredTimeout] = useState(0);
    const [isSessionModalOpen, setModalOpen] = useState(false);
    const logoutTimeout = useMemo(() => {
        const sessionTimeoutMs = sessionTimeout * 1000;
        return sessionTimeoutMs - defaultLogoutOffset > 0 ? sessionTimeoutMs : defaultLogoutTimeout;
    }, [sessionTimeout]);
    const signOut = useCallback(() => {
        LocalStorageUtility.clearKeys([localStorageKey]);
        setModalOpen(false);
        clearAllTimers();
        AuthService.signOut();
    }, [localStorageKey]);
    const clearAllTimers = () => {
        if (sessionPopupTimer)
            clearTimeout(sessionPopupTimer);
        if (automaticLogoutTimer)
            clearTimeout(automaticLogoutTimer);
    };
    const startAllTimers = useCallback(() => {
        sessionPopupTimer = setTimeout(() => {
            setModalOpen(true);
        }, storedTimeout - new Date().getTime() - defaultLogoutOffset);
        automaticLogoutTimer = setTimeout(signOut, storedTimeout - new Date().getTime());
    }, [signOut, storedTimeout]);
    const resetTimers = useCallback(() => {
        isSessionModalOpen && setModalOpen(false);
        clearAllTimers();
        startAllTimers();
    }, [isSessionModalOpen, startAllTimers]);
    const timeoutModalMessage = (<Trans>
            You will be signed out in approximately one minute. Select <b>Stay Signed In</b> to extend your session, or{' '}
            <b>Sign Out</b> to end your session now.
        </Trans>);
    const updateStoredTimeout = () => {
        const timeout = new Date().getTime() + logoutTimeout;
        LocalStorageUtility.setData(localStorageKey, timeout);
        window.dispatchEvent(new Event('storage'));
    };
    const handleStorageChange = () => {
        const timeout = LocalStorageUtility.getData(localStorageKey);
        setStoredTimeout(timeout);
    };
    useEffect(() => {
        if (sessionTimeout) {
            window.addEventListener('storage', handleStorageChange);
            activityEvents.forEach((evt) => window.addEventListener(evt, updateStoredTimeout));
            const storedValue = LocalStorageUtility.getData(localStorageKey);
            if (!storedValue || storedValue - defaultLogoutOffset < new Date().getTime()) {
                updateStoredTimeout();
            }
            else {
                setStoredTimeout(storedValue);
            }
            return () => {
                window.removeEventListener('storage', handleStorageChange);
                activityEvents.forEach((evt) => window.removeEventListener(evt, updateStoredTimeout));
                clearAllTimers();
            };
        }
    }, [sessionTimeout]);
    useEffect(() => {
        if (storedTimeout) {
            resetTimers();
        }
    }, [storedTimeout]);
    return (<AlertModal isOpen={isSessionModalOpen} onClose={updateStoredTimeout} size="sm" title={t `Your session is about to expire`} message={timeoutModalMessage} actions={<>
                    <Button color="secondary" className={styles.uppercaseText} onClick={signOut}>
                        {t `SIGN OUT`}
                    </Button>
                    <Button color="primary" className={styles.uppercaseText} onClick={updateStoredTimeout}>
                        {t `STAY SIGNED IN`}
                    </Button>
                </>}/>);
};
export default SessionTimeoutModal;
