import Oidc from 'oidc-client';
import namespaceService from './namespace.service';
import configurationService from './config.service';
import configService from './config.service';
let oidcManager = null;
const { IdentityBaseClientId, Authority } = configurationService;
const oidcManagerService = {
    GetManager(resetOrigin = false, clientErrorCode = undefined) {
        function getClientOrigin(resetOrigin, clientErrorCode) {
            const clientOrigin = resetOrigin
                ? // E.g. https://orgname.onsolve.com
                    window.location.protocol + '//' + window.location.host
                : window.location.href;
            const clientOriginUrl = new URL(clientOrigin);
            const clientErrorQueryParam = 'client_error';
            // Add `client_error` param if specified, otherwise - make sure it is deleted from the clientOriginUrl.
            // It could contain `noPermissionToLogin`/`signinLinkHasExpired` error code if either error happened while logging in.
            if (clientErrorCode) {
                clientOriginUrl.searchParams.set(clientErrorQueryParam, clientErrorCode);
            }
            else {
                // Clean up `client_error` parameter if it exists.
                clientOriginUrl.searchParams.delete(clientErrorQueryParam);
            }
            // OF-1848: remove "clearAuth" query param from the origin, since at this point the auth data is removed from localStorage
            // and this flag is no longer required in subsequent sign in process
            clientOriginUrl.searchParams.delete('clearAuth');
            return clientOriginUrl.toString();
        }
        if (!oidcManager || resetOrigin) {
            const namespace = namespaceService.getNamespace();
            //* Using onsolve environment for identity redirects
            let origin = `${namespaceService.getProtocol()}//${configService.ExternalDomainName}`;
            if (configService.ClusterSpecificAddress) {
                origin = `${namespaceService.getProtocol()}//${configService.ClusterSpecificAddress}`;
            }
            //! ON-30299: In order to support customers like WTP who want their own CNAME record to work with MFE and Account
            //! Portal we have to update the way that we handle identity server redirects. To that end, we will store the user's
            //! origin in a cookie and use that to redirect the user once we exchange the identity access code for a JWT.
            namespaceService.setOriginCookie();
            const config = {
                storePrefix: 'oidc-user.',
                namespace: namespace,
                authority: Authority,
                clientId: IdentityBaseClientId,
                clientOrigin: getClientOrigin(resetOrigin, clientErrorCode),
                scope: 'openid profile offline_access onsolve-api translations-api.full',
                responseType: 'code',
                responseMode: 'query',
                redirectUri: `${origin}/callback`,
                postLogoutRedirectUri: `${origin}/`,
            };
            oidcManager = this.createManager(config);
        }
        return oidcManager;
    },
    createManager(configSettings) {
        const manager = new Oidc.UserManager({
            stateStore: new Oidc.WebStorageStateStore({
                store: window.sessionStorage,
                prefix: configSettings.storePrefix,
            }),
            userStore: new Oidc.WebStorageStateStore({
                store: window.localStorage,
                prefix: configSettings.storePrefix,
            }),
            authority: configSettings.authority,
            response_mode: configSettings.responseMode,
            response_type: configSettings.responseType,
            client_id: configSettings.clientId,
            scope: configSettings.scope,
            redirect_uri: configSettings.redirectUri,
            post_logout_redirect_uri: configSettings.postLogoutRedirectUri,
            extraQueryParams: {
                namespace: configSettings.namespace,
                origin: configSettings.clientOrigin,
            },
            loadUserInfo: true,
            automaticSilentRenew: false,
            silentRequestTimeout: 90000,
            monitorSession: false,
        });
        return manager;
    },
};
export default oidcManagerService;
