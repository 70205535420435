import { default as groups } from './groups';
import { default as routes } from './routes';
import { default as schedules } from './schedules';
import { default as selfRegistration } from './selfRegistration';
import { default as contacts } from './contacts';
import { default as doNotContact } from './doNotContact';

export default {
    ...routes,
    ...schedules,
    ...groups,
    ...selfRegistration,
    ...contacts,
    ...doNotContact
};
