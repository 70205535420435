import { configureTwitter, editTwitter, useTwitter, viewTwitter } from 'common/constants/permissions';

const canViewTwitter = permissions => !!permissions[viewTwitter];
const canEditTwitter = permissions => !!permissions[editTwitter];
const canUseTwitter = permissions => !!permissions[useTwitter];
const canConfigureTwitter = permissions => !!permissions[configureTwitter];

export default {
    canConfigureTwitter,
    canEditTwitter,
    canUseTwitter,
    canViewTwitter
};
