import React from 'react';
const GroupsIcon = (props) => {
    return (<svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>Group-icon</title>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="New-Nav" transform="translate(-17.000000, -321.000000)" fill="#D8D8D8" fillRule="nonzero">
                    <g id="Group" transform="translate(17.000000, 321.000000)">
                        <g id="Group" transform="translate(0.375000, 0.000000)">
                            <path d="M3.32280444,6.28985507 L2.06868992,6.28985507 C1.14378047,6.28985507 0.375635327,7.42471137 0.265900307,8.89486613 L0.0934595613,11.3193319 C0.0777831298,11.6288381 0.18751815,11.9383444 0.375635327,11.9899288 C1.09675117,12.2478507 1.88057275,12.3768116 2.69574718,12.3768116 C2.99359938,12.3768116 3.29145158,12.3510194 3.55795091,12.3252272 L3.55795091,12.299435 L3.77742095,9.35912552 C3.84012668,8.43060673 4.07527315,7.57946451 4.40447821,6.9088676 C4.10662601,6.52198477 3.73039166,6.28985507 3.32280444,6.28985507 Z" id="Path"></path>
                            <path d="M16.9623674,8.97034183 C16.8523064,7.45759188 16.0818799,6.28985507 15.1542235,6.28985507 L13.8963844,6.28985507 C13.5033096,6.28985507 13.1102349,6.50217086 12.8114981,6.92680242 C13.1416809,7.64336819 13.3618027,8.49263132 13.4246947,9.44805235 L13.6448165,12.5000917 L13.6448165,12.5266312 C13.9278303,12.5531707 14.2108442,12.5797101 14.509581,12.5797101 C15.3271764,12.5797101 16.0976029,12.4470128 16.8365834,12.1816181 C17.0095363,12.1019996 17.1510432,11.8100654 17.1195973,11.4915918 L16.9623674,8.97034183 Z" id="Path"></path>
                            <ellipse id="Oval" cx="3.43125" cy="3.16521739" rx="1.875" ry="2.02898551"></ellipse>
                            <ellipse id="Oval" cx="13.93125" cy="3.16521739" rx="1.875" ry="2.02898551"></ellipse>
                            <path d="M9.54375,6.49275362 L7.6875,6.49275362 C6.35625,6.49275362 5.23125,7.86476762 5.0625,9.70274863 L4.8,12.6797601 C4.7625,13.068066 4.95,13.4304848 5.2125,13.5081459 C6.3,13.8446777 7.44375,14 8.625,14 C9.80625,14 10.95,13.8446777 12.01875,13.5081459 C12.3,13.4304848 12.46875,13.068066 12.45,12.6797601 L12.1875,9.70274863 C12.01875,7.89065467 10.89375,6.49275362 9.54375,6.49275362 Z" id="Path"></path>
                            <ellipse id="Oval" cx="8.775" cy="2.65797101" rx="2.34375" ry="2.53623188"></ellipse>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);
};
export default GroupsIcon;
