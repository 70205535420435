import ApiService from './api.service';
import configurationService from '@services/config.service';
import jwtService from './jwt.service';
class IdentityService extends ApiService {
    constructor() {
        // Construct an api service with IdentityServerUrl
        super(configurationService.IdentityServerUrl);
    }
    getIdentityContext = async () => {
        const { idctxt } = jwtService.getPermissionsClaims();
        if (!idctxt) {
            return { attributes: {} };
        }
        try {
            const response = await this.get(`/api/v1/IdentityContext/${idctxt}`);
            return response ?? { attributes: { 'security:uiSessionTimeoutInSeconds': 3600 } };
        }
        catch (error) {
            console.error(error);
            return { attributes: {} };
        }
    };
    impersonateUser = async (data) => {
        try {
            const response = await this.post('/connect/token', data);
            if (response) {
                return response;
            }
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    };
}
export default new IdentityService();
