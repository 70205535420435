import React, { useContext, createContext, useState, useEffect } from 'react';
import ApiService from 'services/api.service';
import PermissionService from 'services/permission.service';
import localStorageUtility from '../common/utility/localStorageUtility';
import configurationService from 'services/config.service';
const RIAccountContext = createContext(null);
const permissionService = new PermissionService();
export const useRIAccountContext = () => useContext(RIAccountContext);
const RIAccountProvider = ({ children }) => {
    const apiService = new ApiService();
    const { canManageRiskIntelligence } = permissionService.permissions;
    const accountAuthURL = configurationService.RiskIntelligenceApiUrl;
    const [riAccount, setRiAccount] = useState(null);
    const [riConfig, setRiConfig] = useState({});
    const [isLoadingRIAccount, setIsLoadingRIAccount] = useState(true);
    const getRIAuth = async () => {
        try {
            const data = (await apiService.get(`${accountAuthURL}/3/authenticated`)) || {};
            localStorageUtility.setData('riAccountInfo', data);
            setRiAccount(data);
        }
        catch (err) {
            console.info('No Risk Intelligence account authenticated.');
        }
        setIsLoadingRIAccount(false);
    };
    const getRIConfiguration = async (accountId) => {
        try {
            const response = (await apiService.get(`${accountAuthURL}/1/accounts/${accountId}/configuration`)) || {};
            localStorageUtility.setData('riConfigurationInfo', response);
            setRiConfig(response);
        }
        catch (err) {
            console.info('No Risk Intelligence Configurations.');
        }
    };
    useEffect(() => {
        if (canManageRiskIntelligence()) {
            getRIAuth();
        }
    }, []); //eslint-disable-line
    useEffect(() => {
        if (riAccount) {
            getRIConfiguration(riAccount.account_id);
        }
    }, [riAccount]); //eslint-disable-line
    const values = {
        riAccount,
        isLoadingRIAccount,
        riConfig,
    };
    return (<RIAccountContext.Provider value={values}>
            <>{children}</>
        </RIAccountContext.Provider>);
};
export default RIAccountProvider;
