/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RawIntlProvider } from 'react-intl';
import { I18nBlurLoaderProvider } from '@onsolve/onsolve-ui-components';

import defaultLanguage from 'common/constants/defaultLanguage';
import I18nService from 'services/i18n.service';
import localeService from 'services/locale.service';

import { I18nContext } from './context';
import { i18nSetCookie } from './utility';
import { useLingui } from '@lingui/react';

import * as plural from 'make-plural/plurals';

function I18nProvider({ locale, isAppLoading, children }) {
    const [language, setLanguage] = useState(defaultLanguage);
    const [intl, setIntl] = useState(I18nService.intl);
    const [loading, setLoading] = useState(true);
    const { i18n } = useLingui();

    async function loadLanguage(callback) {
        try {
            const { translations, ...data } = typeof callback === 'function' ? await callback() : callback;
            const config = {
                messages: translations,
                locale: 'en'
            };
            const intl = I18nService.create(config);
            const locale = data?.isoCode ?? 'en-US';

            const { messages } = await import(`../../../../locales/${locale}/messages`);
            i18n.load(data?.isoCode ?? 'en-US', messages);

            // Grabbing the plurals dynamically
            i18n.loadLocaleData({
                [locale]: { plurals: plural[locale.slice(0, 2).toLowerCase()] }
            });

            i18n.load(locale, translations);
            i18n.activate(locale);

            document.dir = data?.direction ? data.direction.toLowerCase() : 'ltr';
            setIntl(intl);
            setLanguage(data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isAppLoading) {
            loadLanguage(() => localeService.init(locale ? locale : language.lcId));
        }
    }, [locale, isAppLoading]);

    const updateLanguage = useCallback(code => {
        setLoading(true);
        loadLanguage(() => localeService.getTranslations(code));
        i18nSetCookie(code.lcId);
    }, []);

    const updateLanguageWithData = useCallback(callbackResult => {
        if (typeof callbackResult === 'boolean') {
            setLoading(callbackResult);
        } else if (typeof callbackResult === 'object') {
            const { ...data } = callbackResult;

            loadLanguage(callbackResult);
            i18nSetCookie(data.lcId);
        }
    }, []);

    const value = useMemo(() => ({ loading, language, updateLanguage, updateLanguageWithData }), [
        loading,
        language,
        updateLanguage,
        updateLanguageWithData
    ]);

    return (
        <I18nContext.Provider value={value}>
            <RawIntlProvider value={intl}>
                <I18nBlurLoaderProvider value={loading}>{children}</I18nBlurLoaderProvider>
            </RawIntlProvider>
        </I18nContext.Provider>
    );
}

I18nProvider.propTypes = {
    children: PropTypes.node,
    isAppLoading: PropTypes.bool,
    locale: PropTypes.string
};

I18nProvider.defaultProps = {
    isAppLoading: false
};

export default I18nProvider;
