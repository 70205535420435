import { createContext, useContext } from 'react';

import defaultLanguage from 'common/constants/defaultLanguage';

const I18nContext = createContext({
    language: defaultLanguage,
    updateLanguage: _ => _,
    updateLanguageWithData: _ => _
});

I18nContext.displayName = 'I18nContext';

function useI18n() {
    const context = useContext(I18nContext);

    if (context === undefined) {
        throw new Error(`useI18n must be used within a I18nContext`);
    }
    return context;
}

export { I18nContext, useI18n };
