import {
    FBA_RTBF,
    FBA_allowBlackListedSms,
    FBA_customizeDivisions,
    FBA_customtts,
    FBA_divisions,
    FBA_duplicateFilters,
    FBA_manageDeviceTypes,
    FBA_resetSmsFlagAllowed,
    FBA_smsProfiles,
    FBA_subdivisions,
    assignSmsProfile,
    editAni,
    editCustomVerbiage,
    editLookout,
    editOrgNodes,
    editRoles,
    editSMSList,
    editSOS,
    grantLogin,
    orgAdmin,
    protectedData,
    setSMimeKey,
    viewDivision,
    viewLookout,
    viewReport,
    viewSOS,
    viewUser
} from 'common/constants/permissions';

// Account Defaults
const canViewLabels = permissions => !!permissions[FBA_manageDeviceTypes] && !!permissions[orgAdmin];

// Account Structure
const canViewDataHierarchy = permissions => !!permissions[viewDivision];

const canViewUsers = permissions => {
    return !!permissions[grantLogin] && !!permissions[viewUser];
};

const canViewUsersNew = permissions => permissions[viewUser];

const canViewRoles = permissions => !!permissions[editRoles] && !!permissions[viewDivision];

const canEditRoles = permissions => !!permissions[editRoles] && !!permissions[editOrgNodes];

const canViewManageDivisionSMIMEKeys = permissions => !!permissions[setSMimeKey];

const canViewMoveDivisions = permissions => !!permissions[editRoles];

// Branding
const canViewCustomVerbiage = permissions => !!permissions[editCustomVerbiage] && !!permissions[FBA_customizeDivisions];

// Delivery Settings
const canViewGeneralOptions = permissions => !!permissions[orgAdmin];

const canViewDivisionANI = permissions => !!permissions[orgAdmin];

const canViewCallThrottling = permissions => !!permissions[editAni];

// Device Settings
const canViewPriority = permissions => !!permissions[orgAdmin];

const canViewRetrySettings = permissions => !!permissions[orgAdmin];

const canViewEmail = permissions => !!permissions[orgAdmin];

const canViewSMS = permissions => !!permissions[orgAdmin];

const canViewVoice = permissions => !!permissions[orgAdmin];

// Security
const canViewProtectedDataFields = permissions => !!permissions[orgAdmin] && !!permissions[protectedData];

const canViewDataSubjectRights = permissions => !!permissions[FBA_RTBF];

// Alert module
const canViewAlertRetrieval = permissions => !!permissions[orgAdmin];

const canViewAllowedSMS = permissions => !!permissions[orgAdmin] && !!permissions[FBA_resetSmsFlagAllowed];

const canViewDuplicateFilters = permissions => !!permissions[orgAdmin] && !!permissions[FBA_duplicateFilters];

const canViewAssignSmsProfiles = permissions => !!permissions[assignSmsProfile] && !!permissions[FBA_smsProfiles];

const canViewManageBlockedSMS = permissions => !!permissions[editSMSList] && !!permissions[FBA_allowBlackListedSms];

const canViewEditTSSVoices = permissions =>
    !!permissions[editRoles] &&
    !!permissions[FBA_customtts] &&
    (!!permissions[FBA_divisions] || !!permissions[FBA_subdivisions]);

const canViewSuppressedAlert = permissions => !!permissions[viewReport];

const canViewAlertOptions = permissions => !!permissions[orgAdmin];

const canViewLookOut = permissions => !!permissions[viewLookout];
const canViewSOS = permissions => !!permissions[viewSOS];
const canEditLookOut = permissions => !!permissions[editLookout];
const canEditSOS = permissions => !!permissions[editSOS];

export default {
    canViewAlertOptions,
    canViewLabels,
    canViewDataHierarchy,
    canViewUsers,
    canViewRoles,
    canEditRoles,
    canViewCustomVerbiage,
    canViewGeneralOptions,
    canViewRetrySettings,
    canViewPriority,
    canViewEmail,
    canViewSMS,
    canViewVoice,
    canViewProtectedDataFields,
    canViewDataSubjectRights,
    canViewCallThrottling,
    canViewDivisionANI,
    canViewAlertRetrieval,
    canViewAllowedSMS,
    canViewDuplicateFilters,
    canViewAssignSmsProfiles,
    canViewManageBlockedSMS,
    canViewEditTSSVoices,
    canViewSuppressedAlert,
    canViewManageDivisionSMIMEKeys,
    canViewMoveDivisions,
    canViewUsersNew,
    canViewLookOut,
    canViewSOS,
    canEditLookOut,
    canEditSOS
};
