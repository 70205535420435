import { Button, EyeIcon } from '@onsolve/onsolve-ui-components';
import React from 'react';
import styles from './ImpersonatorBanner.module.scss';
const ImpersonatorBanner = ({ onClose, message }) => (<div className={styles.bannerContainer} role="alert">
        <span className={styles.icon}>
            <EyeIcon size="md"/>
        </span>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonContainer}>
            <Button variant="outline" size="md" onClick={onClose}>
                Exit View
            </Button>
        </div>
    </div>);
export default ImpersonatorBanner;
