import {
    createLockboxShared,
    deleteLockboxShared,
    editLockboxShared,
    viewLockboxShared,
    viewLockboxSecured
} from 'common/constants/permissions';

import { checkResourceAccessByDivisions } from '../utils';
import features from '../features';

export const canViewLockBox = permissions => (!!permissions[viewLockboxShared] || !!permissions[viewLockboxSecured]) && features.canManageLockBox(permissions);

export const canCreateSharedLockBoxFile = permissions => !!permissions[createLockboxShared];

export const canEditSharedLockBoxFile = (permissions, params) => {
    if (!params) {
        return !!permissions[editLockboxShared];
    }

    return checkResourceAccessByDivisions(editLockboxShared, params);
};

export const canDeleteSharedLockBoxFile = (permissions, params) => {
    if (!params) {
        return !!permissions[deleteLockboxShared];
    }

    return checkResourceAccessByDivisions(deleteLockboxShared, params);
};
