import configurationService from './config.service';
import ApiService from './api.service';
import CookieService from './cookie.service';
import defaultLanguage from 'common/constants/defaultLanguage';

class LocaleService {
    apiService = new ApiService(configurationService.MIR3ApiBaseUrl);

    cookieService = new CookieService();

    init(userLocale) {
        const localesResult = this.getLocales(); //if we have a non-empty response in time/cached.

        this.locales = localesResult.length ? localesResult : [defaultLanguage];

        if (this.locales.length) {
            const defaultLang = this.getBrowserCultureLang();
            const cookieLang = this.cookieService.getCookieValue('lang');
            const language = cookieLang || userLocale || defaultLang;
            const formatLang = l => this.getLocaleByCode(l.replace('_', '-'));

            return this.getTranslations(formatLang(language));
        }
    }

    getLocales() {
        return this.apiService.get(`/res/locales.json`).then(response => (this.locales = response));
    }

    getLocaleByCode(code) {
        return this.locales.length > 1
            ? this.locales.find(locale => locale.isoCode == code || locale.cultureLanguageCode == code)
            : this.locales[0];
    }

    getLocaleById(id) {
        return this.locales.length > 1 ? this.locales.find(locale => locale.lcId == id) : this.locales[0];
    }

    getTranslations(language) {
        if (language?.lcId) {
            //We never want to call Tahoe with an undefined language, keep this here for a sanity check.
            return this.apiService.get(`/actions/verbiageLookup/${language?.lcId}`).then(({ data }) => ({
                ...language,
                translations: data
            }));
        } else {
            return { translations: {} };
        }
    }

    getBrowserCultureLang() {
        let browserCultureLang = window.navigator.languages ? window.navigator.languages[0] : null;

        browserCultureLang =
            browserCultureLang ||
            window.navigator.language ||
            window.navigator.browserLanguage ||
            window.navigator.userLanguage;

        return browserCultureLang;
    }

    getOrganizationLocales = async id => {
        return await this.apiService.get(`/actions/organization/locales/${id}`);
    };
}

export default new LocaleService();
