import SessionDefaults from '@configs/SessionDefaults';
import identityService from '@services/identity.service';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
const SessionContext = createContext(SessionDefaults);
export const useSessionContext = () => useContext(SessionContext);
export const SessionProvider = ({ children }) => {
    const [identityContext, setIdentityContext] = useState(SessionDefaults.identityContext);
    const getIdentityContext = async () => {
        const result = await identityService.getIdentityContext();
        setIdentityContext(result);
    };
    useEffect(() => {
        getIdentityContext();
    }, []);
    const values = useMemo(() => ({
        identityContext,
    }), [identityContext]);
    return (<SessionContext.Provider value={values}>
            <>{children}</>
        </SessionContext.Provider>);
};
export default SessionProvider;
