class LocalStorageUtility {
    static getData(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    static getValue(key) {
        return localStorage.getItem(key);
    }

    static setData(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static selectContaining(prefix) {
        for (let key in localStorage) {
            if (!localStorage.hasOwnProperty(key)) {
                continue;
            }

            if (key.startsWith(prefix)) {
                return JSON.parse(localStorage.getItem(key));
            }
        }

        return;
    }

    static clear() {
        localStorage.clear();
    }

    static clearKeys(value) {
        Array.isArray(value) &&
            value.forEach(item => {
                localStorage.removeItem(item);
            });
    }

    static clearKeysContaining(prefix) {
        for (let key in localStorage) {
            if (!localStorage.hasOwnProperty(key)) {
                continue;
            }

            if (key.startsWith(prefix)) {
                localStorage.removeItem(key);
            }
        }
    }
    static getKeyContaining(prefix) {
        for (let key in localStorage) {
            if (!localStorage.hasOwnProperty(key)) {
                continue;
            }

            if (key.startsWith(prefix)) {
                return key;
            }
        }
    }
}

export default LocalStorageUtility;
