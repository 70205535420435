import configurationService from './config.service';
import ApiService from './api.service';
import { mapLayers } from '@configs/mapLayers';
class MapService {
    apiService;
    constructor() {
        this.apiService = new ApiService(configurationService.ControlCenterApiUrl);
    }
    getAccountID = async () => {
        try {
            const response = await this.apiService.get('/api/v1/account');
            const { accountId = null } = response;
            return accountId;
        }
        catch (error) {
            console.error('Error fetching account ID:', error);
            return null;
        }
    };
    getAccountMapDisplay = async () => {
        //Do not wrap this internally in a try-catch exception
        //The back-end will 404 for new users, handle this where this method is called instead.
        const accountId = await this.getAccountID();
        if (accountId === null) {
            throw new Error('Account ID is not available');
        }
        const response = await this.apiService.get(`/api/v1/account/${accountId}/Preferences/Maps`);
        return response ?? this.getDefaultMapViewDetails();
    };
    getDefaultMapViewDetails() {
        return {
            accountId: 0,
            isVisible: true,
            latitude: 0,
            longitude: 0,
            distanceMeasurement: 'kilometers',
            tileSet: mapLayers.LIGHT_MAP,
            zoom: 1.5,
        };
    }
}
export default new MapService();
