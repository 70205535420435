import { createSchedule, deleteSchedule, editSchedule, viewSchedule } from 'common/constants/permissions';

import features from '../features';
import { checkResourceAccessByDivisions } from '../utils';

const canCreateSchedule = permissions => {
    return !!permissions[createSchedule];
};

const canAssignSchedule = (/*{ permissions }*/) => true;

const canDeleteSchedule = (permissions, params) => {
    if (!params) {
        return !!permissions[deleteSchedule];
    }

    //in case of multiple resourceDivisions every of them need to have permission
    return checkResourceAccessByDivisions(deleteSchedule, params);
};
const canEditSchedule = (permissions, params) => {
    if (!params) {
        return !!permissions[editSchedule];
    }

    return checkResourceAccessByDivisions(editSchedule, params);
};

const canViewSchedule = permissions => !!permissions[viewSchedule] && features.canManageRecipientSchedule(permissions);

export default {
    canCreateSchedule,
    canAssignSchedule,
    canDeleteSchedule,
    canEditSchedule,
    canViewSchedule
};
