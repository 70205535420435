import {
    FBA_customReports,
    FBA_RIHistoricalReporting,
    customReports,
    editAdHoc,
    myReport,
    viewAudit,
    viewOrphanedReports,
    viewReport,
    viewReporting,
    FBA_biReportingBasicRi,
    FBA_biReportingBasicTrm,
    FBA_biReportingBasicCc,
    FBA_wtpCustomer,
} from 'common/constants/permissions';

import globalPermissions from '../globalPermissions';

const canViewAdHocReports = (permissions) =>
    !!permissions[editAdHoc] &&
    (!!permissions[myReport] || !!permissions[viewOrphanedReports] || !!permissions[viewReport]);

const canViewAlertHistory = (permissions) =>
    !!permissions[myReport] || !!permissions[viewOrphanedReports] || !!permissions[viewReport];

const canViewCustomReports = (permissions) => !!permissions[customReports] && !!permissions[FBA_customReports];

const canViewHistoricalReporting = (permissions) => !!permissions[FBA_RIHistoricalReporting];

const canViewReporting = (permissions) => !!permissions[viewReporting];

const canViewUsage = (permissions) => globalPermissions.canOrganizationAdmin(permissions);

const canViewAuditTrail = (permissions) => !!permissions[viewAudit];

const canViewBIReporting = (permissions) =>
    !!permissions[FBA_biReportingBasicRi] ||
    !!permissions[FBA_biReportingBasicTrm] ||
    !!permissions[FBA_biReportingBasicCc];

const canViewWTPCustomer = (permissions) => !!permissions[FBA_wtpCustomer];

export default {
    canViewAdHocReports,
    canViewAlertHistory,
    canViewHistoricalReporting,
    canViewAuditTrail,
    canViewCustomReports,
    canViewReporting,
    canViewUsage,
    canViewBIReporting,
    canViewWTPCustomer,
};
