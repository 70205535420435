import { createUser, deleteUser, exportUser, grantLogin, importUser } from 'common/constants/permissions';

const canCreateContact = permissions => !!permissions[createUser];
const canDeleteContact = permissions => !!permissions[deleteUser];
const canGrantLoginAccess = permissions => !!permissions[grantLogin];
const canImportContacts = permissions => !!permissions[importUser];
const canExportContacts = permissions => !!permissions[exportUser];

export default {
    canCreateContact,
    canDeleteContact,
    canGrantLoginAccess,
    canImportContacts,
    canExportContacts
};
