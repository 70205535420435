import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function forwardTo(location, state) {
    history.push(location, state);
}
const historyService = {
    forwardTo,
    history
};

export default historyService;
